import { useState,useEffect } from "react";

export function useGetOPSAM(result){
    const[username, setUsername] = useState("no user")
    const[token, setToken] = useState("")

    useEffect(() => {


        async function getOPSAM(result){
          if (sessionStorage.getItem("uname") != null){
            
            setUsername(sessionStorage.getItem("uname"));
          } else {
            if (result != null){
              setToken(result.accessToken)
              var req = await fetch("https://graph.microsoft.com/v1.0/me?$select=id,onPremisesSamAccountName",{
                method:"GET",
                headers:{
                  'Authorization': `Bearer ${result.accessToken}`
                }
              });
              var data = await req.json();
              
              setUsername(data.onPremisesSamAccountName);
              sessionStorage.setItem("uname", data.onPremisesSamAccountName);
            }
          }
        }
       
        getOPSAM(result);
      }, [result]);

    return {username, token}
}
