
import ResetForm from "./components/resetForm"
import {useGetOPSAM} from './hooks/useGetOPSAM'
import { AuthenticatedTemplate, useMsalAuthentication,} from "@azure/msal-react";
import {InteractionType} from '@azure/msal-browser';
import {loginRequest} from './authConfig'

export default function App() {

  const {login, result, error} = useMsalAuthentication(InteractionType.Redirect, loginRequest);
  const {username, token} = useGetOPSAM(result)
  
  return (
    <AuthenticatedTemplate>
      <div className="bg-blue-200 min-h-screen flex flex-col justify-center items-center flex-1">
        <div className="flex flex-col justify-center items-center rounded-lg border-black bg-gradient-to-b from-blue-900 via-blue-700 to-blue-500 text-white text-shadow font-size-14 p-20 rounded-bl-15 rounded-br-15 shadow-md">
            
          <h1 className="font-bold text-2xl mb-4">Swisslog Password Reset</h1> 
          <h2 className="mb-4">Current user: {username.toUpperCase()}</h2> 
          <p className="font-bold mb-4">Passwords must contain at least 8 characters, must start with a letter, and must include at least 1 number. Special characters are NOT allowed.</p>
          {username !== "no user" ? <ResetForm userName={username} accessToken={token}/> : <p></p>}
        </div>
      </div>
    </AuthenticatedTemplate>

  );
}