import { useState, useEffect } from "react";
import { endPoint } from "../authConfig";

export default function ResetForm({userName, accessToken}){
   
    const [password1, setPassword1] = useState("")
    const [password2, setPassword2] = useState("")
    const [isValidPW, setIsValidPw] = useState(true)
    const [pwsMatch, setPwsMatch] = useState(true)
    const [rdcList, setRdcList] = useState([])
    const [rdc, setRdc] = useState("")
    
    useEffect(() => {
        async function getRdcs(){
            var req =  await fetch(`${endPoint}getrdcs`,{
                method: "GET",
                headers:{
                    'Authorization': accessToken
                }
            });
        
            let data =  await req.json();
            setRdc(data[0])
            setRdcList(data)
        }
        
        getRdcs()
        
    }, [])
    
    useEffect(() => {
        function handlePw1Change(){
            
   
            if(password1.match(/^(?=.*[0-9])[a-zA-Z][a-zA-Z0-9]{7,}$/) != null){
        
                setIsValidPw(true)
            }else{
                setIsValidPw(false)
            }
            

        }
        if(password1 !== ""){
            handlePw1Change()
        }
       
    }, [password1])
    
    useEffect(() => {
        function handlePw2Change(){
           
        
            if(password2 === password1){
                
                setPwsMatch(true)
            }else{
                setPwsMatch(false)
    
            }
        }
       
        handlePw2Change()
    }, [password2])
    

    
    function changePw(event){
        event.preventDefault()
        
        if(isValidPW === true && pwsMatch === true && password1 !== "" && password2 !== ""){
            const body = {
                rdc: rdc,
                username: userName,
                password: password1
            }



            fetch(`${endPoint}resetpw`, {
                method:"POST",
                headers:{
                    'Authorization' : accessToken
                },
                body:JSON.stringify(body)
            }).then((response) => response.json())
            .then((data)=>{
                window.alert(`${data[0].STATUS}: ${data[0].MESSAGE}`)
            })
            
            setPassword1("")
            setPassword2("")
        }

    }

    return(
        <div className="pt-10 ">
            <form onSubmit={changePw}>
                <label className="block text-white text-md font-bold mb-2 text-center">Databases</label>
                <select className="bg-gray-50 border border-gray-300 text-gray-900 mb-2 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" onChange={(option) => setRdc(option.target.value)}>
                    {
                        rdcList.map((data)=>{
                            return <option value={data}>{data}</option>
                        })
                    }
                </select>
            
                <label className="block text-white  text-md font-bold mb-2 text-center">password</label>
                <input className="shadow appearance-none border border-zinc-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" placeholder="New Password" autoComplete="off" type="password" required value={password1} onChange={(e)=>{ setPassword1(e.target.value)}}></input>
                {isValidPW ? <p></p> : <p className="text-bold text-red-600 font-bold"> Violated or failed to meet a password requirement</p>}
                <label className="block text-white text-md  font-bold mb-2 text-center">confirm password</label>
                <input className="shadow appearance-none border border-zinc-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" placeholder="Confirm New Password" autoComplete="off" type="password" required value={password2} onChange={(e)=>{setPassword2(e.target.value)}}></input>
                {pwsMatch ? <p></p> : <p  className="text-bold text-red-600 font-bold"> Passwords do not match! </p>}
                <button className="bg-blue-700 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full mt-4" type="submit">Submit</button>
            
            </form>
           
        
        </div>
    )
}